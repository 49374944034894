<template>
  <div>
    <div class="title">{{ text.title }}</div>

    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab">
          <tab
            v-for="(value, key) in tabs"
            :key="key"
            :label="value.label"
            :locked="value.locked"
            @click="$router.push({ name: `app-feedback-${value.value}` })"
          />
        </v-tabs>
      </v-col>
    </v-row>

    <div class="mt-3">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Tab from "@/view/components/Common/Tab.vue";

const tabs = {
  feedback: {
    label: "In-App Feedback",
    value: "index",
  },
  comments: {
    label: "Comments",
    value: "comments",
  },
};

export default {
  components: { Tab },
  data() {
    return {
      tabs,
      text: {
        title: "Feedback",
      },
      tab: 0,
    };
  },
  computed: {
    tabList() {
      return Object.values(this.tabs);
    },
  },
  created() {
    this.tab =
      this.$route.name === "app-feedback-comments"
        ? this.tabList.findIndex((v) => v.value === this.tabs.comments.value)
        : 0;
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
}
</style>