var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.text.title))]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, _vm._l(_vm.tabs, function (value, key) {
    return _c('tab', {
      key: key,
      attrs: {
        "label": value.label,
        "locked": value.locked
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: "app-feedback-".concat(value.value)
          });
        }
      }
    });
  }), 1)], 1)], 1), _c('div', {
    staticClass: "mt-3"
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }